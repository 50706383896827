<template>
  <div class="movie_info_add">
<!--    <NavBar title="信息填写"></NavBar>-->

    <van-cell-group inset>
      <van-field readonly v-model="name" label="影院对外名" />
      <van-field readonly v-model="realname" label="工商注册名" />
      <van-field readonly v-model="address" label="地点" />
      <van-field readonly v-model="halls"  label="影厅数" />

      <p class="user_label">联系人姓名</p>
      <input type="text" placeholder="请输入..." class="user_name" v-model="new_user_name" >
      <p class="user_label">联系方式</p>
      <input type="text" placeholder="请输入..." class="user_tel" v-model="new_user_tel" >
    </van-cell-group>

    <div class="submit">
      <button class="btn_sub" @click="sub">提交</button>
    </div>
  </div>
</template>

<script>
import { Field, CellGroup, Toast } from 'vant';
import NavBar from "../components/NavBar";
import { GetCinemaInfo,UpdataCinemaInfo } from "../axios/api"
import {Loading} from "element-ui";
/*import {Message} from "element-ui";*/
export default {
  name: "MovieInfoAdd",
  created() {
    if(localStorage.getItem("token")){
      let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
      GetCinemaInfo({token:localStorage.getItem("token")}).then(respose=>{
        this.realname=respose.data.realname;
        this.name=respose.data.name;
        this.address=respose.data.address;
        this.halls=respose.data.halls;
        this.new_user_name=respose.data.linkman;
        this.new_user_tel=respose.data.contact;
        this.old_user_name=respose.data.linkman;
        this.old_user_tel=respose.data.contact;
        loadingInstance.close();
      }).catch(error=>{
        console.log(error);
      })
    }else{
      // 没有用户token
      /*Message.error("非法操作，请重新登录");*/
      Toast({message:"非法操作，\n请重新登录",icon:"cross"})
      this.$router.push("/cinemaLogin")
    }
  },
  components:{
    [Field.name]:Field,
    [CellGroup.name]:CellGroup,
    NavBar,
  },
  data(){
    return{
      realname:'',
      name:'',
      address:'',
      halls:'',
      old_user_name:'',
      old_user_tel:'',
      new_user_name:'',
      new_user_tel:''
    }
  },
  methods:{
    sub(){
      //判断用户是否输入内容
      if(this.new_user_tel&&this.new_user_name){
        /*用户是否修改过*/
        if(this.old_user_name!==this.new_user_name || this.old_user_tel!==this.new_user_tel){
          // 内容是否合法 ×
          let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
          UpdataCinemaInfo({token:localStorage.getItem("token"),linkman:this.new_user_name,contact:this.new_user_tel}).then(respose=>{
            if(respose.state==="1"){
              /*Message.success("修改成功");*/
              Toast.success({message:"修改成功"})
              /*跳转回首页*/
              if(localStorage.getItem("identity")==="0"){
                this.$router.push("/unMemberUser")
              }else if(localStorage.getItem("identity")==="1"){
                this.$router.push("/memberUser")
              }
            }
            loadingInstance.close();
          }).catch(error=>{
            console.log(error);
          })
        }else{
          //用户没有修改信息 提交
          Toast.fail({message:"你还没有修改信息"})
          /*Message.warning("你还没有修改信息");*/
        }
      }else{
        // 内容为空 提示用户
        /*Message.warning("请确认是否填写内容");*/
        Toast.fail({message:"请先填写内容"})
      }
    }
  }
}
</script>

<style scoped>
.movie_info_add{
  padding-top: 51px;
}
>>>.van-hairline--top-bottom::after{
  border-width:0;
}
.movie_info_add>>>.van-cell-group--inset {
  margin: 0 auto 265px;
  padding: 0px 34px 62px 36px;
  width: 620px;
  border-radius: 20px;
  background: #FFFFFF;
}
.movie_info_add>>>.van-field:nth-of-type(1) {
  padding: 58px 0 20px;
}
.movie_info_add>>>.van-field:nth-of-type(4) {
  margin-bottom: 100px;
}
.movie_info_add>>>.van-field {
  padding: 66px 0 20px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  border-bottom: 2px solid #F2F2F2;
}
.movie_info_add>>>.van-field__label {
  color: #353535 !important;
}
.movie_info_add>>>.van-field__control {
  text-align: right;
  color: #343434;
}
.movie_info_add>>>.van-cell::after {
  border-bottom: 0px;
}
.user_name,
.user_tel {
  width: calc(560px);
  height: calc(39px);
  background: rgba(227, 229, 238, 0.38);
  border-radius: 9px;
  border: 0px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  padding: 20px 31px;
}
.user_label {
  width: 620px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #353535;
  line-height: 35px;
  margin: 13px 0;
}
.user_label:nth-last-of-type(1) {
  margin: 32px 0 13px 0;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
}
.submit .btn_sub {
  margin-top: 18px;
  width: 691px;
  height: 89px;
  border: 0;
  background: linear-gradient(270deg, #3054F8 0%, #4768FF 100%);
  box-shadow: 0 0 18px 3px rgba(66, 95, 220, 0.35);
  border-radius: 41px 41px 41px 41px;
  opacity: 0.87;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}

</style>
